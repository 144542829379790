module.exports = {
	'--red': 'hsl(4, 78%, 57%)',

	/* Font stack */
	'--fallback-fonts': 'Univers, "Helvetica Neue", Helvetica, "Myriad Pro", Myriad, Geneva, Arial, sans-serif',
	'--base-font-family': '"Surveyor SSm Light", Univers, "Helvetica Neue", Helvetica, "Myriad Pro", Myriad, Geneva, Arial, sans-serif',
	'--base-font-family--bold': '"Surveyor SSm Medium", Univers, "Helvetica Neue", Helvetica, "Myriad Pro", Myriad, Geneva, Arial, sans-serif',
	'--base-font-family--italic': '"Surveyor SSm Medium Italic", Univers, "Helvetica Neue", Helvetica, "Myriad Pro", Myriad, Geneva, Arial, sans-serif',
	'--nav-font-family': '"Surveyor SSm Book"Italic, Univers, "Helvetica Neue", Helvetica, "Myriad Pro", Myriad, Geneva, Arial, sans-serif',
	'--font-size--small': '1.3rem',
	'--font-size--med': '1.5rem',
	'--font-size--full': '2.0rem',
	'--line-height': '1.7',

	/* Font stack */
	'--nav-width': '60px',

	/* Type colors */
	'--highlight': 'hsl(259, 100%, 52%)',
	'--gold': 'hsl(38, 28%, 70%)',
	'--gold--dark': 'hsl(38, 28%, 60%)',
	'--orange': 'rgb(236, 107, 50)',
	'--text-color': 'rgb(60, 60, 60)',
	'--text--light': 'rgb(180, 180, 180)',


	/* Chapters */
	'--chapter-header-background': 'rgb(240, 236, 229)',
	'--cover-width': 'calc(50vw - var(--nav-width))',
	'--cover-vw-width': 'calc(1067 / 2560 * 100vw)',
	'--content-vw-width': 'calc(1493 / 2560 * 100vw)',
	'--contentPadding--top': '10vh',
	'--content-width': 'calc(50vw - var(--nav-width))',
	'--chapterPadding--top': `calc(
		var(--contentPadding--top)
		- (
			var(--font-size--full)
			- var(--chapterNumberSize)
		)
		+ var(--chapterNumberPadding--bottom)
		+ (
			var(--chapterTitleSize)
			* var(--chapterTitleLeading)
		)
	)`,

	/* Chapter: Cover */
	'--chapterNumberSize': '1.8vmin',
	'--chapterNumberLeading': '1',
	'--chapterNumberPadding--bottom': '1.5em',
	'--chapterTitleSize': '7.5vmin',
	'--chapterTitleLeading': '0.8',


	/* Animations */
	'--duration': '600ms',
	// '--easing': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
	'--easing': 'cubic-bezier(0.23, 1, 0.32, 1)',

	/* Inbox */
	'--maxContentWidth': '700px',
}
