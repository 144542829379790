import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import vars from '../style/vars'

// Components
import Footer from '../components/Footer'
import SEO from '../components/seo'

// Style
import style from './chapter.module.css'

type TemplateTypes = {
	data: {
		markdownRemark: {
			frontmatter: {
				chapter: number
				date: string
				htmlTitle: string
				poster: {
					childImageSharp: {
						fluid: object
					}
				}
				posterCaption: string
				title: string
			}
			html: string
		}
	}
	location: {
		href: string
	}
	pageContext: {
		next: {
			frontmatter: {
				path: string
				title: string
			}
		}
		prev: {
			frontmatter: {
				path: string
				title: string
			}
		}
	}
}

export default function Template({
	data,
	location,
	pageContext,
}: TemplateTypes) {
	const { markdownRemark } = data // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark
	const featuredImgFluid = frontmatter.poster.childImageSharp.fluid
	const featuredImgCaption = frontmatter.posterCaption || null

	const chapter = frontmatter.chapter === 0 ? 'Prologue' : `Chapter ${frontmatter.chapter}`
	const pageTitle = `${chapter}: ${frontmatter.title}`

	return (
		<>
			<SEO
				image={ `https://amemoirProject.com${featuredImgFluid.src}` }
				title={ pageTitle }
				url={ location.href }
			/>
			<article className={ style.Chapter }>
				<header className={ style.Header }>
					<hgroup className={ style.HGroup }>
						<h3 className={ style.ChapterNumber }>{ chapter }</h3>
						{
							frontmatter.htmlTitle ? (
								<h1 className={ style.Title } dangerouslySetInnerHTML={ { __html: frontmatter.htmlTitle } }></h1>
							) : <h1 className={ style.Title }>{ frontmatter.title }</h1>
						}
					</hgroup>
					<BackgroundImage
						backgroundColor={ vars['--chapter-header-background'] }
						className={ style.HeaderFigure }
						fluid={ featuredImgFluid }
						style={ {
							backgroundPosition: 'center top',
							backgroundSize: 'contain',
						} }
						Tag="figure"
					/>
					<div className={ style.Credits }>
						{
							featuredImgCaption ? (
								<p className={ style.CaptionText }>Photo: { featuredImgCaption }</p>
							) : null
						}
						<p>Written by Michael Blankenburg</p>
						<p>Published { frontmatter.date }</p>
					</div>
				</header>
				<div className={ style.Body }>
					<div className={ style.Masthead }>
						<div className={ style.MastheadInterior }>
							<Link to="/">A Memoir Project</Link>
							{ ' ' }
							<span className={ style.Divider } />
							{ ' ' }
							<span>{ frontmatter.chapter === 0 ? 'Prologue' : `Chapter ${frontmatter.chapter}` }: { frontmatter.title }</span>
						</div>
					</div>
					<div className={ style.TextSizer } dangerouslySetInnerHTML={ { __html: html } } />
					<div className={ style.Pagination }>
						<div className={ style.Prev }>
							{
								pageContext.prev ? (
									<Link to={ pageContext.prev.frontmatter.path }>
										<span className={ style.Label }>Previous Chapter</span>
										{ pageContext.prev.frontmatter.title }
									</Link>
								) : null
							}
						</div>
						<div className={ style.Next }>
							{
								pageContext.next ? (
									<Link to={ pageContext.next.frontmatter.path }>
										<span className={ style.Label }>Next Chapter</span>
										{ pageContext.next.frontmatter.title }
									</Link>
								) : null
							}
						</div>
					</div>
					<Footer />
				</div>
			</article>
		</>
	)
}

export const pageQuery = graphql`
	query Test($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				chapter
				date(formatString: "MMMM DD, YYYY")
				htmlTitle
				path
				title
				poster {
					childImageSharp {
						fluid(maxWidth: 1080) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				posterCaption
			}
		}
	}
`
